import React from 'react';
import HeaderExer from './Header/HeaderExer';
import PlayList from './VideoSlider/PlayList';
import KOOSForm from './Form/KOOSForm';



const PageVideoCouse = () => (
    <>
        <HeaderExer />
        <KOOSForm />
        <PlayList />
    </>
);

export default PageVideoCouse;
