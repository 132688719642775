import React, { useState } from "react";
import "./KOOSForm.scss"; // Стилі форми

const KOOSForm = () => {
  const koosQuestions = [
    {
      category: "Біль",
      questions: [
        "Чи відчували ви біль у коліні при підйомі або спуску по сходах?",
        "Чи відчували ви біль у коліні при ходьбі по рівній поверхні?",
        "Чи відчували ви біль у коліні при вставанні з положення сидячи?",
        "Чи відчували ви біль у коліні вночі під час сну?",
        "Чи відчували ви біль у коліні при стоянні впродовж 30 хвилин?",
        "Чи відчували ви біль у коліні після фізичних навантажень?",
      ],
    },
    {
      category: "Симптоми",
      questions: [
        "Чи відчували ви скутість (жорсткість) у коліні після пробудження вранці?",
        "Чи відчували ви скутість у коліні після тривалого сидіння чи відпочинку?",
        "Чи було у вас припухання в коліні?",
        "Чи хрумтів або клацав ваш колінний суглоб при русі?",
        "Чи відчували ви нестабільність (підкошування) коліна?",
      ],
    },
    {
      category: "Функція, повсякденна активність",
      questions: [
        "Чи відчували ви труднощі при спуску по сходах?",
        "Чи відчували ви труднощі при підйомі по сходах?",
        "Чи відчували ви труднощі при вставанні з положення сидячи?",
        "Чи відчували ви труднощі при стоянні?",
        "Чи відчували ви труднощі при підйомі з ліжка?",
        "Чи відчували ви труднощі при вході та виході з автомобіля?",
        "Чи відчували ви труднощі при покупках (наприклад, носінні сумок)?",
        "Чи відчували ви труднощі при ходьбі по рівній поверхні?",
        "Чи відчували ви труднощі при митті підлоги або пилососінні?",
      ],
    },
    {
      category: "Спортивна активність",
      questions: [
        "Чи відчували ви труднощі при бігу?",
        "Чи відчували ви труднощі при стрибках?",
        "Чи відчували ви труднощі при присіданнях?",
        "Чи відчували ви труднощі при коліноприклонному положенні?",
      ],
    },
    {
      category: "Якість життя",
      questions: [
        "Чи усвідомлюєте ви наявність проблем із коліном у повсякденному житті?",
        "Чи змушені ви адаптувати свій спосіб життя через коліно?",
        "Наскільки часто ви відчуваєте проблеми з коліном?",
        "Чи можете ви повністю довіряти своєму коліну?",
      ],
    },
  ];

  // Загальна кількість питань
  const totalQuestions = koosQuestions.reduce((acc, category) => acc + category.questions.length, 0);

  const [answers, setAnswers] = useState(Array(totalQuestions).fill(0));
  const [activeCategory, setActiveCategory] = useState(null);
  const [result, setResult] = useState(null);

  // Функція для обробки змін у відповідях
  const handleChange = (index, value) => {
    const newAnswers = [...answers];
    newAnswers[index] = parseInt(value);
    setAnswers(newAnswers);
  };

  // Функція для підрахунку результату
  const calculateResult = () => {
    const totalScore = answers.reduce((acc, curr) => acc + curr, 0);
    setResult(totalScore);
  };

  // Функція для відкриття блоку з питаннями
  const toggleCategory = (index) => {
    setActiveCategory(activeCategory === index ? null : index);
  };

  // Функція для отримання індексу питання в масиві answers
  const getQuestionIndex = (catIndex, qIndex) => {
    let index = 0;
    for (let i = 0; i < catIndex; i++) {
      index += koosQuestions[i].questions.length;
    }
    return index + qIndex;
  };

  return (
    <div className="koos-form-container">
      <h2 className="koos-title">Оцінка травми коліна та остеоартриту KOOS</h2>
      <p className="koos-sutitle">
        ІНСТРУКЦІЇ. У цьому опитуванні необхідно надати ваше бачення щодо вашого коліна. Це інформація допоможе вам зрозуміти
        степінь порушення функції коліна. Дайте відповідь на кожне запитання, відзначивши відповідну клітинку, лише одну клітинку
        для кожного запитання. Якщо ви не впевнені, як відповісти на запитання, надайте найкращу відповідь, яку ви можете.
      </p>

      {koosQuestions.map((category, catIndex) => (
        <div key={catIndex} className="koos-category">
          <button className="koos-category-btn" onClick={() => toggleCategory(catIndex)}>
            {category.category}
          </button>
          {activeCategory === catIndex && (
            <div className="koos-questions">
              {category.questions.map((question, qIndex) => (
                <div className="koos-question" key={qIndex}>
                  <label>{question}</label>
                  <select
                    onChange={(e) => handleChange(getQuestionIndex(catIndex, qIndex), e.target.value)}
                    value={answers[getQuestionIndex(catIndex, qIndex)]}
                  >
                    <option value="0">0 - Немає проблем</option>
                    <option value="1">1 - Легкі труднощі</option>
                    <option value="2">2 - Помірні труднощі</option>
                    <option value="3">3 - Значні труднощі</option>
                    <option value="4">4 - Дуже важко</option>
                  </select>
                </div>
              ))}
            </div>
          )}
        </div>
      ))}

      <button className="koos-submit-btn" onClick={calculateResult}>
        Підрахувати результат
      </button>

      {result !== null && (
        <div className="koos-result">
          <h3>Ваш результат: {result}</h3>
          <p>
            {result <= 80
              ? "Ваше коліно функціонує добре! Можливо, є незначний дискомфорт, але серйозних обмежень немає. Для профілактики варто підтримувати фізичну активність."
              : result <= 60
              ? "Є помірні проблеми з коліном. Ви можете відчувати біль або скутість у певних ситуаціях. Рекомендується програма реабілітації для зміцнення суглоба."
              : result <= 40
              ? "Ви маєте суттєві обмеження у функції коліна. Це впливає на повсякденне життя та активність. Вам варто пройти курс реабілітації, щоб відновити рухливість і зменшити біль."
              : "Ваш стан потребує уваги! Значні труднощі з рухом та болем можуть вказувати на серйозне порушення. Вам рекомендовано пройти спеціалізовану програму відновлення або звернутися до спеціаліста."}
          </p>
          <h4>Який етап реабілітації вам підходить?</h4>
          <p>
            {result <= 80
              ? "Вам підходить заключний етап реабілітації, спрямований на зміцнення та повернення до активного способу життя. Цей етап включає інтенсивні вправи на витривалість, координацію та вибухову силу."
              : result <= 60
              ? "Вам підходить функціональний етап реабілітації, що включає вправи для підвищення сили м’язів, стабільності суглоба та поступової підготовки до активних навантажень."
              : result <= 40
              ? "Вам підходить середній етап реабілітації, який допоможе зменшити біль, покращити контроль м’язів і відновити рухливість. Включає вправи на баланс, стабільність і помірні силові навантаження."
              : "Вам підходить початковий етап реабілітації, який допоможе зменшити біль, зняти запалення та відновити базову рухливість. Основний акцент на м’які мобілізаційні та ізометричні вправи."}
          </p>

          <a href="tg://resolve?domain=Bogdan_rehab_bot" className="koos-course-btn">
            Перейти до курсу
          </a>
        </div>
      )}
    </div>
  );
};

export default KOOSForm;