import React from 'react'
import Button from '../../components/Button/Button';
import Title from '../../components/Text/Title/Title';
import {motion} from 'framer-motion'
import './price.scss'
import Accordion from './Accordion'

const animate = {
    hidden:{
        y: 100,
        opacity: 0,
    },
    visible: custom => ({
        y: 0,
        opacity: 1,
        transition: {delay: custom * 0.1 }
    }),
};

let price = [
    // {
    //     id: 1,
    //     title:'Реабілітація з виїздом на дом',
    //     price:'від 900 грн/60-70 хв.',
    //     btn:'Записатись',
    //     detal: 'Детальніше',
    //     trg: 'На першому сеансі спочатку роблю первинний огляд і збір анамнезу. Після чого проводиться перше заняття. В залежності від проблематики, я використовую різні методики як масажу і маніпуляцій, так і вправ, тобто індивідуальний і комплексний підхід. Після першого сеансу визначається тактика та можливі варіанти реабілітації. Також даю рекомендації для подальшої підтримки здоровʼя.'
    // },
    {
        id: 2,
        title:'Онлайн консультація + програма реабілітації',
        price:'1000 грн/60 хв.',
        btn:'Записатись',
        detal: 'Детальніше',
        trg: 'Якщо маєш проблеми зі спиною та суглобами і немає можливості або часу звертатись до кваліфікованого реабілітолога за лікуванням офлайн, та їздити регулярно на реабілітацію, то онлайн консультація - найкраща альтернатива. Цей варіант підходить для тих хто цінує свій час, адже Вам не знадобиться їхати кудись і тратити багато часу на це.👍 Отримайте всі відповіді на питання просто не виходячи з дому і проходьте реабілітацію в любий зручний час для Вас😀 У вартість консультації входить: 1.Збір історії хвороби. 2.Тестування мʼязів та суглобів. 3.Консультація ( уточнюючі запитання, додаткові тести ) 4.Тестові вправи на поточні фізичні можливості та техніку. 5.Підбір програми лікувальних вправ. 6.Ретест попереднього результату. 7. Відповіді на питання та обговорення стратегії лікування Після консультації даю рекомендації та створюю індивідуальну програму з вправами і самомасажем у відеоматеріалі на 2 тижні. І протягом цих 2 тижнів підтримуємо звʼязок та аналізуємо результати.'
    },
    // {
    //     id: 3,
    //     title:'Побудова програми реабілітації',
    //     price:'500 грн',
    //     btn:'Записатись',
    //     detal: 'Детальніше',
    //     trg: 'Реабілітація вдома онлайн має багато переваг. По-перше, це зручніше для пацієнта, оскільки йому не потрібно їздити на прийом і назад. По-друге, це доступніше, ніж традиційні послуги амбулаторної реабілітації. По-третє, це дозволяє пацієнту отримувати терапію в приватному житті власного дому, або з любої точки світу. Нарешті, це може забезпечити більш гнучкий графік для пацієнта.'
    // },
    // {
    //     id: 4,
    //     title:'Перший сеанс',
    //     price:'500 грн/45 хв.',
    //     btn:'Записатись',
    //     detal: 'Детальніше',
    //     trg: 'Маєте дискомфорт або біль, але не знаєте на яку послугу записатись? Вибирай цю послугу. На сеансі я проводжу огляд та збираю всю історію хвороби, тестую та проводжу лікувальні заходи. Оцінюю оптимальну тактику лікування та даю рекомендації.'
    // },
    // {
    //     id: 5,
    //     title:'Дитячий масаж',
    //     price:'450 грн/30 хв.',
    //     btn:'Записатись',
    //     detal: 'Детальніше',
    //     trg: 'Дитячий масаж дуже корисний дітям дошкільного та шкільного віку, які мають певні проблеми з поставою. Масаж можна виконувати як в лікуванні так і в профілактиці захворювань опорно-рухового апарату.'
    // },
    // {
    //     id: 6,
    //     title:'Масаж + гімнастика 0-1 року',
    //     price:'450 грн/30 хв.',
    //     btn:'Записатись',
    //     detal: 'Детальніше',
    //     trg: 'Масаж та гімнастика в грудному віці сприяє нормальному фізичному розвитку дитини. Також такі сеанси призначаються при різних проблемах опорно-рухового апарату та патологіях (дисплазія, кривошия, гіпертонус, відставання в розвитку тощо). На сеансі Ви отримаєте також рекомендації як займатись з дитиною вдома для більшого результату від курсу.'
    // },
    // {
    //     id: 7,
    //     title:'Лікувальний масаж 30 хв',
    //     price:'500 грн/30 хв.',
    //     btn:'Записатись',
    //     detal: 'Детальніше',
    //     trg: "Лікувальний масаж - це не ліки, а гостра необхідність для кожного, хто піклується про своє здоров'я.  Процедури виконуються у різний спосіб, які вибираються виходячи з потреб пацієнта.  Сеанс лікувального масажу може виконуватися як самостійна форма терапії, і як додаткова процедура, що використовується при комплексному лікуванні."
    // },
    // {
    //     id: 8,
    //     title:'Лікувальний масаж 60 хв',
    //     price:'900 грн/60 хв.',
    //     btn:'Записатись',
    //     detal: 'Детальніше',
    //     trg: "Лікувальний масаж - це не ліки, а гостра необхідність для кожного, хто піклується про своє здоров'я.  Процедури виконуються у різний спосіб, які вибираються виходячи з потреб пацієнта.  Сеанс лікувального масажу може виконуватися як самостійна форма терапії, і як додаткова процедура, що використовується при комплексному лікуванні."
    // },
    // {
    //     id: 9,
    //     title:'Реабілітація',
    //     price:'900 грн/60 хв.',
    //     btn:'Записатись',
    //     detal: 'Детальніше',
    //     trg: "Реабілітація - це комплекс лікувальних та профілактичних заходів, який спрямований на максимально можливе відновлення втрачених здібностей пацієнта після різних захворювань.На першому сеансі спочатку роблю первинний огляд і збір анамнезу. Після чого проводиться перше заняття. В залежності від проблематики, я використовую різні методики як масажу і маніпуляцій, так і вправ, тобто індивідуальний і комплексний підхід. Після першого сеансу визначається тактика та можливі варіанти реабілітації. Також даю рекомендації для подальшої підтримки здоровʼя."
    // },
];

const Price = () => (
    <div className='price'>
        <motion.ul initial='hidden' whileInView='visible' viewport={{amout: 0.1, once: true,}}>
            {price.map(item=>
                <motion.li custom={item.id} variants={animate} key={item.id} className='price__li'>
                    <div className='price__title'>
                        <Title>{item.title}</Title>
                    </div>
                    <div className='price__subtitle'>
                        <Title>{item.price}</Title>
                    </div>
                    <div className='price__btn'>
                        <a href='https://www.bogdan-rehab.com.ua/#/contacts'><Button>{item.btn}</Button></a>
                    </div>
                    <div>
                        <Accordion btn={item.detal} text={item.trg}/>
                    </div>
                </motion.li>)}
        </motion.ul>
    </div>
);

export default Price;
